import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const AddContact = ({ successCallBackFunction, hideAddContact, RelatedCompany, showComponent }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [emailErrorMessage, setEmailErrorMessage] = useState("");
	const [mobileErrorMessage, setMobileErrorMessage] = useState("");
	const [generatorCompanyIdErrorMessage, setGeneratorCompanyIdErrorMessage] = useState("");
	const [nationalIdErrorMessage, setNationalIdErrorMessage] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [email, setEmail] = useState("");
	const [nationalId, setNationalId] = useState("");
	const [type, setType] = useState("");
	const [generatorCompanyId, setGeneratorCompanyId] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [companyId, setCompanyId] = useState("");
	const [other, setOther] = useState("");
	const [generators, setGenerators] = useState([]);
	const [generatorType, setGeneratorType] = useState([]);
	const [transporterCompanyId, setTransporterCompanyId] = useState("");
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	useEffect(() => {
		async function fetchData() {
			try {
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				const response = await fetch(Constants.GetTransporterGeneratorsData + selectedCompany, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + accessToken,
					},
				});
				const responseText = await response.text();

				try {
					const newData = JSON.parse(responseText);
					let arrayOfGenerators = Object.values(newData);
					const generators = arrayOfGenerators.flatMap((generator) => {
						let parsedgenerator;
						try {
							parsedgenerator = typeof generator === "string" ? JSON.parse(generator) : generator;
						} catch (e) {
							console.error("Error parsing task:", task, e);
							return []; // Skip this task if it can't be parsed
						}
						parsedgenerator.id = t(parsedgenerator.id);
						parsedgenerator.generetorCompanyId = t(parsedgenerator.generetorCompanyId);
						parsedgenerator.generatedWasteType = t(parsedgenerator.generatedWasteType);
						parsedgenerator.wasteType = parsedgenerator.wasteType;
						parsedgenerator.unn = t(parsedgenerator.unn);
						parsedgenerator.crNumber = t(parsedgenerator.crNumber);
						parsedgenerator.address = t(parsedgenerator.address);
						parsedgenerator.companyName = t(parsedgenerator.companyName);
						parsedgenerator.phoneNumber = t(parsedgenerator.phoneNumber);
						return parsedgenerator;
					});
					setGenerators(generators);
				} catch (jsonError) {
					console.error("Response text:", jsonError);
				}
			} catch (networkError) {
				console.error("Fetch error:", networkError);
			}
			//}
		}

		fetchData();
	}, []);

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (compTypeID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			console.log(responseObject);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		// console.log(compTypeID);
		var endPoint = Constants.GetCompaniesByType + compTypeID;
		General.GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
	};

	/*************** LOAD COMPANIES END ************************/

	const handleDropDownChange = () => {};

	const handleCancelNew = () => {
		hideAddContact();
	};

	const handleAddContact = async () => {
		setIsFinish(true);

		//get all elements
		var fieldfirstName = document.getElementById("firstName");
		var fieldlastName = document.getElementById("lastName");
		var fieldaddress = document.getElementById("address");
		var fieldnationalId = document.getElementById("nationalId");
		var fieldmobileNumber = document.getElementById("mobileNumber");
		var errorContainerDate = document.getElementById("errorContainerDate");
		var fieldemail = document.getElementById("email"); // For email validation
		var AddContactJSON = {
			firstName: firstName,
			lastName: lastName,
			address: address,
			nationalId: nationalId,
			companyId: companyId,
			type: type,
			email: email,
			generatorCompanyId: generatorCompanyId,
			other: other,
			mobileNumber: mobileNumber,
		};
		var isValidForm = true;
		//check if fields are filled and set the invalid class in case they are not
		fieldnationalId.classList.remove("is-invalid");
		// Validate National ID
		console.log({ companyId });
		if (companyId == "") {
			setGeneratorCompanyIdErrorMessage(t("Select Company"));
			setIsFinish(false);
			console.log({ companyId });
			console.log({ generatorCompanyId });
			isValidForm = false;
		}
		if (generatorCompanyId == "") {
			setGeneratorCompanyIdErrorMessage(t("Select Company"));
			setIsFinish(false);
			isValidForm = false;
		}
		if (generatorCompanyId == "0") {
			fieldmobileNumber.classList.remove("is-invalid");
			setEmailErrorMessage(t(""));
			// Phone Number validation: must be 10 digits and start with '05'
			if (mobileNumber.length !== 10 || !mobileNumber.startsWith("05")) {
				fieldmobileNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setMobileErrorMessage(t("Mobile Number must be 10 digits and start with '05'."));
				setIsFinish(false);
				isValidForm = false;
			}
		} else {
			if (!nationalId || nationalId.length !== 10) {
				setIsFinish(false);
				setNationalIdErrorMessage(t("National ID must be exactly 10 characters long."));
				showErrorContainer(true);
				fieldnationalId.classList.add("is-invalid");
				isValidForm = false;
			}

			fieldmobileNumber.classList.remove("is-invalid");
			// Phone Number validation: must be 10 digits and start with '05'
			if (mobileNumber.length !== 10 || !mobileNumber.startsWith("05")) {
				fieldmobileNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setMobileErrorMessage(t("Mobile Number must be 10 digits and start with '05'."));
				setIsFinish(false);
				isValidForm = false;
			}
			fieldemail.classList.remove("is-invalid");
			if (!emailRegex.test(email)) {
				fieldemail.classList.add("is-invalid");
				showErrorContainer(true);
				setEmailErrorMessage(t(t("Please enter a valid email address.")));
				setIsFinish(false);
				isValidForm = false;
			}
		}
		if (isValidForm == false) {
			return;
		} else {
			const AddContactSuccess = (responseObject) => {
				toast.success("Success");
				handleCancelNew();
				successCallBackFunction(true);
				setIsFinish(false);
			};

			const AddContactFailure = (error) => {
				setTimeout(() => {
					setIsFinish(false);
				}, timeOut);

				toast.error("An error occured, please try again later.", {
					autoClose: timeOut,
				});
			};

			var AddContactJSONString = JSON.stringify(AddContactJSON);
			var AddContactEndPoint = Constants.AddTransporterContactData + RelatedCompany;
			General.PostToEndPoint(AddContactEndPoint, null, AddContactJSONString, AddContactSuccess, AddContactFailure);
		}
	};
	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon={faUser} className="text-success" />
						</div>
						<div className="ms-2">{t(`New Delegate`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("Company")}</label>
							<select
								id="companyId"
								className={`form-select ${generatorCompanyIdErrorMessage ? "is-invalid" : ""}`}
								onChange={(e) => {
									const generatorId = e.target.value; // Get the selected generator ID
									const selectedGenerator = generators.find((generator) => generator.id === generatorId); // Find the corresponding generator object
									if (generatorId == "") {
										setGeneratorCompanyIdErrorMessage(t("Select"));
									} else {
										setGeneratorCompanyIdErrorMessage(t(""));
									}
									if (selectedGenerator) {
										setGeneratorCompanyId(generatorId); // Set the generator ID
										setCompanyId(selectedGenerator["generetorCompanyId"]);
										setGeneratorType(selectedGenerator["wasteType"]); // Set the generator's company ID
									} else {
										setGeneratorCompanyId(generatorId);
										setCompanyId("Individual");
									}
								}}
							>
								<option value="">{t("Select")}</option>
								<option key={0} value="0">
									{t("Individual")}
								</option>
								{generators.length > 0 &&
									generators.map((generator) => (
										<option key={generator.id} value={generator.id}>
											{t(generator.companyName)}
										</option>
									))}
							</select>
							{generatorCompanyIdErrorMessage && <div className="invalid-feedback">{generatorCompanyIdErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`First Name`)}</label>
							<input type="text" id="firstName" className="form-control" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
						</div>

						<div className="mb-3">
							<label className="form-label">{t(`Last Name`)}</label>
							<input type="text" id="lastName" className="form-control" value={lastName} onChange={(event) => setLastName(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("National ID/Iqama ID")}
								{generatorCompanyId != "0" && <span style={{ color: "red" }}>*</span>}
							</label>
							<input
								type="text"
								id="nationalId"
								className={`form-control ${nationalId.length === 10 ? "" : nationalId.length > 0 ? "is-invalid" : ""}`} // Apply invalid class if length is not 10
								value={nationalId}
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict length to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setNationalId(input);
										setNationalIdErrorMessage(t("National ID must be exactly 10 characters long."));
									} else {
										setNationalIdErrorMessage(t(""));
									}
								}}
							/>
							{nationalIdErrorMessage && <div className="invalid-feedback">{nationalIdErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Mobile Number`)}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="mobileNumber"
								className={`form-control ${mobileNumber.length === 10 && mobileNumber.startsWith("05") ? "" : mobileNumber.length > 0 ? "is-invalid" : ""}`} // Highlight if invalid
								value={mobileNumber}
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										setMobileNumber(input);
										setMobileErrorMessage(t("Mobile number must start with '05' and be exactly 10 digits long."));
									} else {
										setMobileErrorMessage("");
									}
								}}
							/>
							{mobileErrorMessage && <div className="invalid-feedback">{mobileErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Address`)}</label>
							<input type="text" id="address" className="form-control" value={address} onChange={(event) => setAddress(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Email`)}
								{generatorCompanyId != "0" && <span style={{ color: "red" }}>*</span>}
							</label>
							<input
								type="email"
								id="email"
								className="form-control"
								value={email}
								onChange={(event) => {
									setEmail(event.target.value);
									const input = event.target.value;
									if (!emailRegex.test(input)) {
										setEmailErrorMessage(t("Please enter a valid email address."));
									} else {
										setEmailErrorMessage(t(""));
									}
								}}
							/>
							{emailErrorMessage && <div className="invalid-feedback">{emailErrorMessage}</div>}
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
					<IconButton size="sm" icon="check" className="ms-2" variant="primary" onClick={handleAddContact}>
						{t("Confirm")}
					</IconButton>
				</div>

				{/* {errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)} */}
			</Modal.Footer>
		</Modal>
	);
};

AddContact.propTypes = {
	showComponent: PropTypes.bool,
};

export default AddContact;
