import React, { useState, useContext, useEffect } from "react";
import PageHeader from "components/common/PageHeader";
import Hero from "./Hero";
import AppContext from "context/Context";
import Services from "./Services";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import FaqAccordionItem from "../faq/faq-accordion/FaqAccordionItem";
import { faqAccordions as faqsData } from "data/faqs";
import PdfDownload from "./PdfDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import LandingBackground from "assets/img/landing_background_bottom.jpg";
import WhatsApp from "assets/img/whatsapp.png";
import DownloadDoc from "assets/img/download_document.png";
import styles from "./landing.module.css";
import LandingButtons from "./LandingButtons";
import Carousel from "./Carousel";
import carouselImg from "assets/img/e-learning/courses/course8.png";
import jeddahmunicipality from "assets/img/jeddah-municipality.png";
import momra from "assets/img/momra.png";
import tga from "assets/img/tga.png";
import { getAllDatabaseRecords, getDatabaseRecord } from "components/workflow/WorkflowHelper";
import createMarkup from "helpers/createMarkup";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Landing = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const [faqs] = useState(faqsData);
	const language = isRTL ? "ar" : "en";
	const formatDate = (date, language) => {
		const locale = language === "ar" ? "ar-EG" : "en-US"; // Choose Arabic or English locale
		return new Intl.DateTimeFormat(locale, {
			year: "numeric",
			day: "numeric",
			month: "long",
		}).format(new Date(date));
	};
	const [heroSlider, setHeroSlider] = useState([]);
	const [aboutUs, setAboutUs] = useState([]);
	const [partners, setPartners] = useState([]);
	const [news, setNews] = useState([]);
	const getTableData = async (tableName, fieldValue) => {
		try {
			const result = await getAllDatabaseRecords(tableName, fieldValue);
			return result; // return the result if the function succeeds
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // rethrow the error to be handled elsewhere if necessary
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTableData("home_slider", "show=eq.true");

				if (response?.data?.dataBaseContent) {
					// Map dataBaseContent to the desired structure
					const mappedData = response.data.dataBaseContent.map((item) => ({
						title: t(item.title), // Translate title
						subtitle: t(item.subTitle), // Translate subTitle
						imageUrl: item.imageUrl, // Use the image URL directly
					}));

					// Update state with the fetched and mapped data
					setHeroSlider((prev) => [...prev, ...mappedData]); // Append new data to existing
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching slider data:", error);
			}
			try {
				const response = await getTableData("about_us", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					const mappedData = response.data.dataBaseContent.map((item) => ({
						title: t(item.aboutTitle),
						aboutText: t(item.aboutText),
					}));

					setAboutUs((prev) => [...prev, ...mappedData]);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching about data:", error);
			}
			try {
				const response = await getTableData("partners", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					const mappedData = response.data.dataBaseContent.map((item) => ({
						id: item.id,
						title: t(item.title),
						image: item.image,
						order: item.order,
					}));
					const sortedData = mappedData.sort((a, b) => a.order - b.order);
					setPartners((prev) => [...prev, ...sortedData]);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching partners data:", error);
			}
			try {
				const response = await getTableData("news_table", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					const mappedData = response.data.dataBaseContent.map((item) => ({
						id: item.id,
						title: t(item.title),
						image: item.image,
						description: t(item.description),
					}));
					setNews((prev) => [...prev, ...mappedData]);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching news data:", error);
			}
		};

		fetchData();
	}, []);
	return (
		<div className={`${styles.backgroundWhite}`}>
			<NavbarStandard />
			<Hero titleText={heroSlider} />
			<div
				style={{
					backgroundImage: `url(${LandingBackground})`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					justifyContent: "center",
				}}
			>
				<Container
					style={{
						padding: "3.5em",
					}}
				>
					{aboutUs.map((text, index) => (
						<div key={index}>
							<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{text.title}</h2>
							<div className={`${styles.landingText} ${styles.size18}`} dangerouslySetInnerHTML={createMarkup(text.aboutText)} />
						</div>
					))}
				</Container>
				<LandingButtons textColor="#ffffff" />
			</div>
			{news.length > 0 && (
				<Container className={`${styles.padding35em} `}>
					<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("Latest news")}</h2>
					<Carousel dots={true} slidesToShowLarge={3} infinite={false} slidesToShowMid={2} slidesToShowS={1}>
						{news.map((item) => (
							<div key={item.id} className={styles.carouselcontainer} style={{ padding: "10px" }}>
								<div className={styles.carouselImage} style={{ backgroundImage: `url(${item.image})` }}></div>
								<div className={isRTL ? styles.carouselTitleAr : styles.carouselTitle}>{t(item.title)}</div>
								<div className={isRTL ? styles.carouselDateAr : styles.carouselDate}>
									<FontAwesomeIcon icon="fa-regular fa-clock" className={styles.textGreen} />
									{item.date && formatDate(item.date, language)}
								</div>
							</div>
						))}
					</Carousel>
				</Container>
			)}
			{partners.length > 0 && (
				<Container className={`${styles.padding35em} `}>
					<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("Success partners")}</h2>
					<Carousel dots={false} infinite={false} slidesToShowLarge={5} slidesToShowMid={3} slidesToShowS={2}>
						{partners.map((item) => (
							<div key={item.id} className={styles.carouselPartnercontainer} style={{ padding: "20px", margin: "10px" }}>
								<img className={styles.carouselPartnersImage} src={item.image} />
							</div>
						))}
					</Carousel>
				</Container>
			)}
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Landing;
