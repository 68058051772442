import React, { useState, useContext, useEffect } from "react";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";
import createMarkup from "helpers/createMarkup";
import { getAllDatabaseRecords } from "components/workflow/WorkflowHelper";
import LandingBackground from "assets/img/landing_background_bottom.jpg";
import LandingButtons from "./LandingButtons";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const AboutUs = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [aboutUs, setAboutUs] = useState([]);
	const getTableData = async (tableName, fieldValue) => {
		try {
			const result = await getAllDatabaseRecords(tableName, fieldValue);
			return result; // return the result if the function succeeds
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // rethrow the error to be handled elsewhere if necessary
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTableData("about_us", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					const mappedData = response.data.dataBaseContent.map((item) => ({
						title: t(item.aboutTitle),
						aboutText: t(item.aboutText),
					}));

					setAboutUs((prev) => [...prev, ...mappedData]);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching about data:", error);
			}
		};

		fetchData();
	}, []);
	const [t, i18n] = useTranslation();
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100} `}>
			<NavbarStandard />
			<div
				style={{
					//backgroundImage: `url(${LandingBackground})`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					justifyContent: "center",
				}}
			>
				<Container
					style={{
						padding: "3.5em",
					}}
				>
					{aboutUs.map((text, index) => (
						<div key={index}>
							<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle} mb-4`}>{text.title}</h2>
							<div className={`${styles.accordionStyle} ${styles.size18}`} dangerouslySetInnerHTML={createMarkup(text.aboutText)} />
						</div>
					))}
				</Container>
				<LandingButtons textColor="#000000" />
			</div>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default AboutUs;
