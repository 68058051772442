import React, { useState, useContext, useEffect } from "react";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";
import createMarkup from "helpers/createMarkup";
import { getAllDatabaseRecords } from "components/workflow/WorkflowHelper";
import LandingBackground from "assets/img/landing_background_bottom.jpg";
import LandingButtons from "./LandingButtons";
import IconButton from "components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Manuals = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [manuals, setManuals] = useState([]);
	const getTableData = async (tableName, fieldValue) => {
		try {
			const result = await getAllDatabaseRecords(tableName, fieldValue);
			return result; // return the result if the function succeeds
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // rethrow the error to be handled elsewhere if necessary
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTableData("user_manuals", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					// Map the raw data
					const mappedData = response.data.dataBaseContent.map((item) => ({
						title: t(item.title),
						to: item.to,
						parentTitle: item.parentTitle,
						parentOrder: item.parentOrder,
						isDownload: t(item.to),
						order: item.order,
					}));

					// Group data by parentTitle
					const groupedData = mappedData.reduce((acc, item) => {
						if (!acc[item.parentTitle]) {
							acc[item.parentTitle] = {
								items: [],
								parentOrder: item.parentOrder, // Track the parentOrder for sorting
							};
						}
						acc[item.parentTitle].items.push(item);
						return acc;
					}, {});

					// Sort parent groups by parentOrder and then sort each group by order
					const sortedGroupedData = Object.entries(groupedData)
						.sort(([, a], [, b]) => a.parentOrder - b.parentOrder) // Sort parent groups by parentOrder
						.reduce((acc, [key, value]) => {
							acc[key] = value.items.sort((a, b) => a.order - b.order); // Sort each group by order
							return acc;
						}, {});

					setManuals(sortedGroupedData);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching manual data:", error);
			}
		};

		fetchData();
	}, []);
	const [t, i18n] = useTranslation();
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100} `}>
			<NavbarStandard />
			<Container className={`${styles.padding35em} ${styles.addPageHeight} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle} mb-4`}>{t("User Manual")}</h2>
				{Object.entries(manuals).map(([parentTitle, items]) => (
					<div key={parentTitle} className="manual-group">
						<h2 className={`${isRTL ? styles.manualTitleAr : styles.manualTitle} ${styles.manualPadding20}  mb-2`}>{t(parentTitle)}</h2>
						{items.map((item, index) => (
							<div className={styles.downloading} key={index}>
								<a className={`text-600 ${styles.downloadinglink}  ${styles.manualPadding50}`} target="_blank" href={item.to} download>
									<FontAwesomeIcon icon="fa-solid fa-download" className={styles.downloadingButton} />
									<span className={styles.downloadingText}>{item.title}</span>
								</a>
							</div>
						))}
					</div>
				))}
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Manuals;
