// src/IndexedDB.js
import Dexie from "dexie";

const db = new Dexie("MwanLocalDB");

db.version(4).stores({
    pending_tasks: "++id, pendingTasks",
    submission_data: "++id, submissionData",
    temp_form_data: "[formId+processInstanceId+taskID], formId, processInstanceId, taskID, tempFormData", // Compound primary key
    current_workflow_step: "++id, currentWorkflowStep",
    dropdown_data: "++id, url, timestamp"
});

db.open().catch(async function (e) {
    console.error("Open failed: " + e.stack);
    //in case of error, delete the database and reload the page
    await db.delete();
    window.location.reload();
})

// Helper functions for form data operations
export const saveFormData = async (formData) => {
    if (!formData.formId) return;
    try {
        const key = {
            formId: formData.formId,
            processInstanceId: formData.processInstanceId || '',
            taskID: formData.taskID || ''
        };
        
        //this will update if exists, create if doesn't
        await db.temp_form_data.put({
            ...key,
            tempFormData: JSON.stringify(formData),
            lastUpdated: new Date().toISOString()
        });
    } catch (error) {
        console.error('Error saving form data:', error);
    }
};

export const getFormData = async (formId, processInstanceId = '', taskID = '') => {
    try {
        const key = {
            formId: formId,
            processInstanceId: processInstanceId,
            taskID: taskID
        };
        
        const entry = await db.temp_form_data.get(key);
        return entry ? JSON.parse(entry.tempFormData) : null;
    } catch (error) {
        console.error('Error getting form data:', error);
        return null;
    }
};

export const deleteFormData = async (formId, processInstanceId = '', taskID = '') => {
    try {
        // Create compound key as array
        const key = [formId, processInstanceId || '', taskID || ''];
        
        await db.temp_form_data.delete(key);
    } catch (error) {
        console.error('Error deleting form data:', error);
    }
};

export default db;
