import React from "react";
import axios from "axios";
import * as Constants from "./constants";
import { EncryptStorage } from "encrypt-storage";

export const encryptStorage1 = new EncryptStorage("secret-key-value", {
	prefix: "@mwan",
});

export function PostToEndPoint(EndPoint, HeaderParameters, JsonToPost, PostSuccessCallBack, PostFailureCallBack) {
	var headers = { "Content-Type": "application/json", ...HeaderParameters };

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	if (accessToken) {
		var headerToken = { Authorization: "Bearer " + accessToken };
		headers = { ...headers, ...headerToken };
	}

	axios
		.post(EndPoint, JSON.parse(JSON.stringify(JsonToPost)), {
			headers: headers,
		})
		.then((response) => {
			if (response != null) {
				if (response.data != null) {
					var jsonObjectResponse = response.data;

					PostSuccessCallBack(jsonObjectResponse);
				}
			}
		})
		.catch((error) => {
			// Handle error
			PostFailureCallBack(error);
		});
}

export function PutToEndPoint(EndPoint, HeaderParameters, JsonToPut, PutSuccessCallBack, PutFailureCallBack) {
	var headers = { "Content-Type": "application/json", ...HeaderParameters };

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

	if (accessToken) {
		// var accToken = accessToken;
		// var refToken = localStorage.getItem(Constants.RefreshTokenKeyInLocalStorage);
		var headerToken = { Authorization: "Bearer " + accessToken };
		headers = { ...headers, ...headerToken };
	}

	axios
		.put(EndPoint, JsonToPut, {
			headers: headers,
		})
		.then((response) => {
			if (response != null) {
				PutSuccessCallBack(response.data);
			}
		})
		.catch((error) => {
			// Handle error
			PutFailureCallBack(error);
		});
}

export function PatchToEndPoint(EndPoint, HeaderParameters, JsonToPatch, PatchSuccessCallBack, PatchFailureCallBack) {
	var headers = { "Content-Type": "application/json", ...HeaderParameters };

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

	if (accessToken) {
		var headerToken = { Authorization: "Bearer " + accessToken };
		headers = { ...headers, ...headerToken };
	}

	axios
		.patch(EndPoint, JsonToPatch, {
			headers: headers,
		})
		.then((response) => {
			if (response != null) {
				if (response.data != null) {
					var jsonObjectResponse = response.data;
					if (jsonObjectResponse.status == Constants.LogoutStatus) {
						//      LogoutUser();
					} else {
						PatchSuccessCallBack(jsonObjectResponse);
					}
				}
			}
		})
		.catch((error) => {
			// Handle error
			PatchFailureCallBack(error);
		});
}

export function GetFromEndPoint(EndPoint, HeaderParameters, Parameters, GetSuccessCallBack, GetFailureCallBack, isLoggedIn = false) {
	var headers = { ...HeaderParameters };
	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

	var successfulResponse = false;
	if (accessToken) {
		// var accToken = accessToken;
		// var refToken = localStorage.getItem(Constants.RefreshTokenKeyInLocalStorage);
		var headerToken = { Authorization: "Bearer " + accessToken };
		headers = { ...headers, ...headerToken };
	} else if (isLoggedIn) {
		LogoutUser();
	}

	axios
		.get(EndPoint, { Parameters, headers })
		.then((response) => {
			if (response != null) {
				if (response.data != null) {
					var jsonObjectResponse = response.data;

					if (jsonObjectResponse.status == Constants.LogoutStatus) {
						// LogoutUser();
					} else {
						GetSuccessCallBack(jsonObjectResponse);
					}
				}
			}
		})
		.catch((error) => {
			// Handle error
			GetFailureCallBack(error);
		});
}

export function DeleteFromEndPoint(EndPoint, HeaderParameters, Parameters, DeleteSuccessCallBack, DeleteFailureCallBack) {
	var headers = { "Content-Type": "application/json", ...HeaderParameters };

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

	if (accessToken) {
		var headerToken = { Authorization: "Bearer " + accessToken };
		headers = { ...headers, ...headerToken };
	}

	axios
		.delete(EndPoint, { headers: headers, data: Parameters })
		.then((response) => {
			if (response != null) {
				if (response.data != null) {
					var jsonObjectResponse = response.data;
					if (jsonObjectResponse.status == Constants.LogoutStatus) {
						//ogoutUser();
					} else {
						DeleteSuccessCallBack(jsonObjectResponse);
					}
				}
			}
		})
		.catch((error) => {
			// Handle error
			DeleteFailureCallBack(error);
		});
}

export function LogoutUser() {
	localStorage.setItem(Constants.AccessTokenKeyInLocalStorage, "");
	localStorage.setItem(Constants.RefreshTokenKeyInLocalStorage, "");
	window.location.href = "/";
}

export function CheckIfTokenExists() {
	var tokenExists = false;

	if (localStorage.getItem(Constants.AccessTokenKeyInLocalStorage)) {
		if (localStorage.getItem(Constants.AccessTokenKeyInLocalStorage) != "") {
			tokenExists = true;
		}
	}

	if (tokenExists == false) {
		LogoutUser();
	}
	return tokenExists;
}

export function SaveToken(responseObject) {
	localStorage.setItem(Constants.AccessTokenKeyInLocalStorage, responseObject.data.token.accessToken);
	localStorage.setItem(Constants.RefreshTokenKeyInLocalStorage, responseObject.data.token.refreshToken);
}

export function isValidEmail(email) {
	// Regular expression to match email format
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	return emailRegex.test(email);
}
export function isKSAPhoneNumber(phoneNumber) {
	// Regular expression to match KSA PHone number
	const pattern = /^((\+|00)966|0)?5\d{8}$/;
	return pattern.test(phoneNumber);
}

export function validateRoleAttribute(roleAttribute) {
	var attributes = JSON.parse(localStorage.getItem(Constants.LoggedInRolesAttributesKeyInLocalStorage));
	if (attributes.includes(roleAttribute)) {
		return true;
	}

	return false;
}

export function formatTimestamp(timestamp) {
	const date = new Date(timestamp);
	const options = {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: true,
	};
	return new Intl.DateTimeFormat("en-GB", options).format(date);
}

export function getCompanyTypeInfo(companyTypeID) {
	var companyTypeInfo = null;
	if (companyTypeID == "1") {
		companyTypeInfo = {
			name: "Generator",
			icon: "industry",
			pluralName: "Generators",
		};
	} else if (companyTypeID == "2") {
		companyTypeInfo = {
			name: "Facility",
			icon: "industry",
			pluralName: "Facilities",
		};
	} else if (companyTypeID == "3") {
		companyTypeInfo = {
			name: "Transporter",
			icon: "truck",
			pluralName: "Transporters",
		};
	}
	return companyTypeInfo;
}

export function getFormatInYMD(dateToFormat) {
	const year = dateToFormat.getFullYear();
	const month = String(dateToFormat.getMonth() + 1).padStart(2, "0");
	const day = String(dateToFormat.getDate()).padStart(2, "0");

	const formattedString = `${year}-${month}-${day}`;
	return formattedString;
}

export function SaveInLocalStorage(key, value) {
	encryptStorage1.setItem(key, value);
}

export function GetFromLocalStorage(key) {
	const value = encryptStorage1.getItem(key);
	if (value == undefined) {
		return "";
	}

	return value;
}

export async function GetLoggedInUserData(userId) {
	const GetLoggedInUserData = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetLoggedInUserData + userId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetLoggedInUserData;
}

export async function GetCompanyUsersData(queryString) {
	const GetCompanyUsersData = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetCompanyUsersData + queryString, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetCompanyUsersData;
}

export async function GetCompanyRoles(companyID) {
	const GetCompanyRoles = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetCompanyRolesData + companyID, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetCompanyRoles;
}

export async function PostToDb(body) {
	const PostToDb = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(Constants.PostEndPoint, {}, body, GetSuccessCallBack, GetFailureCallBack);
	});
	return PostToDb;
}

export async function DeleteRoles(body) {
	const DeleteFromDb = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		DeleteFromEndPoint(Constants.DeleteCompanyRolesData, {}, body, GetSuccessCallBack, GetFailureCallBack);
	});
	return DeleteFromDb;
}

export async function AddUser(companyId, keycloakCompanyId, isAdmin, body) {
	const addUser = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(Constants.AddUserData + companyId + "/" + keycloakCompanyId + "/" + isAdmin, {}, body, GetSuccessCallBack, GetFailureCallBack);
	});
	return addUser;
}

export async function UnassignUser(userId, companyId, keycloackCompanyId) {
	const unassignUser = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		DeleteFromEndPoint(Constants.UnassignUserData + userId + "/" + companyId + "/" + keycloackCompanyId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return unassignUser;
}

export async function GetUserCompanies(userId) {
	const GetUserCompanies = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetUserCompaniesData + userId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetUserCompanies;
}
export async function GetServices(tableName, fieldName, fieldValue) {
	const GetServices = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetEndPoint + "/" + tableName + "/" + fieldName + "/" + fieldValue, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetServices;
}

export async function GetRolebyUserTask(tableName, fieldName, fieldValue) {
	const GetRolebyUserTask = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetEndPoint + "/" + tableName + "/" + fieldName + "/" + fieldValue, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetRolebyUserTask;
}
export async function GetUserIdByEmail(tableName, fieldName, fieldValue) {
	const GetUserIdByEmail = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetEndPoint + "/" + tableName + "/" + fieldName + "/" + fieldValue, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetUserIdByEmail;
}

export async function SetAssigneeForTask(data) {
	const {
	  taskId,
	  userId,
	  tableName, 
	  fieldName,
	  createdId,
	  processId,
	  userInfo,
	  driverInfo,
	  selectedCompany
	} = data;
  
	return new Promise((resolve, reject) => {
	  const PostSuccessCallBack = (response) => resolve(response);
	  const PostFailureCallBack = (response) => reject(response);
  
	  const endPoint = `${Constants.setAssignEndPoint}${taskId}/${userId}/` +
		`${tableName}/${fieldName}/${createdId}/${processId}/` +
		`${userInfo.loggedInUserFullName}/${driverInfo.oldDriver.fullName}/` +
		`${driverInfo.oldDriver.phoneNumber}/${driverInfo.newDriver.fullName}/` +
		`${driverInfo.newDriver.phoneNumber}/${selectedCompany}`;
  
	  PostToEndPoint(endPoint, {}, {}, PostSuccessCallBack, PostFailureCallBack);
	});
  }

export async function GetAllRolesDTO() {
	const GetCompanyRoles = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(Constants.GetAllRolesDTOEndPoint, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return GetCompanyRoles;
}