import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import createMarkup from "helpers/createMarkup";
import Section from "components/common/Section";
import IconGroup from "components/common/icon/IconGroup";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { blogPostList, menuList1, menuList2 } from "data/footer";
import { bgWhiteIcons } from "data/socialIcons";
import { version } from "config";
import logo from "assets/img/logo/mwanLogo-white.png";
import styles from "./landing.module.css";
import { useTranslation } from "react-i18next";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { getAllDatabaseRecords } from "components/workflow/WorkflowHelper";

const FooterTitle = ({ children }) => <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>;

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
	<ul className="list-unstyled">
		{list.map(({ title, to, isDownload = false }, index) => (
			<li className="mb-1" key={index}>
				{isDownload ? (
					<a className={`text-600 ${styles.footerLink}`} target="_blank" href={to} download>
						{title}
					</a>
				) : (
					<Link className={`text-600 ${styles.footerLink}`} to={to}>
						{title}
					</Link>
				)}
			</li>
		))}
	</ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
	<ul className="list-unstyled">
		{list.map((blog, index) => (
			<li key={index}>
				<h5 className="fs-0 mb-0">
					<Link className="text-600" to="#!">
						{blog.title}
					</Link>
				</h5>
				<p className="text-600 opacity-50">
					{blog.date} &bull; {blog.read} read {blog.star && <span dangerouslySetInnerHTML={createMarkup("&starf;")} />}
				</p>
			</li>
		))}
	</ul>
);
FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const Footer = () => {
	const [t, i18n] = useTranslation();
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};
	const aboutPlatform = [
		{ title: t("Overview"), to: "#!" },
		{ title: t("Our partners"), to: "#!" },
	];
	const [socialmedia, setSocialmedia] = useState([]);

	const getTableData = async (tableName, fieldValue) => {
		try {
			const result = await getAllDatabaseRecords(tableName, fieldValue);
			return result; // return the result if the function succeeds
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // rethrow the error to be handled elsewhere if necessary
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTableData("social_links", "show=eq.true");

				if (response?.data?.dataBaseContent) {
					// Map dataBaseContent to the desired structure
					const mappedData = response.data.dataBaseContent.map((item) => ({
						id: item.id,
						link: item.link,
						icon: item.icon,
					}));
					// Update state with the fetched and mapped data
					setSocialmedia((prev) => [...prev, ...mappedData]); // Append new data to existing
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching social data:", error);
			}
		};

		fetchData();
	}, []);
	return (
		<>
			<Section className={`pt-6 pb-4 light ${styles.backgroundNavy}`}>
				<Row className="mb-3">
					<Col lg={3} className="mb-3">
						<img src={logo} className={`${styles.logosizeFOOTER}`} />
					</Col>
					<Col lg={3} xs={12} md={3} className="mb-3">
						<FooterTitle>
							<Link className={`${styles.footerLink}`} to={"/aboutus"}>
								{t("About the platform")}
							</Link>
						</FooterTitle>
					</Col>
					<Col lg={3} xs={12} md={3} className="mb-3">
						<FooterTitle>
							<Link className={`${styles.footerLink}`} to={"/manuals"}>
								{t("User Manual")}
							</Link>
						</FooterTitle>
					</Col>
					<Col lg={3} xs={12} md={3}>
						<FooterTitle>{t("Follow us")}</FooterTitle>
						<div className="icon-group">
							{socialmedia.map((item) => (
								<a key={item.id} href={item.link} target="_blank">
									<FontAwesomeIcon icon={item.icon.toLowerCase().includes("twitter") ? faXTwitter : "fa-brands fa-" + item.icon} className={styles.footerIcon} />
								</a>
							))}
						</div>
					</Col>
				</Row>
				<Row className="mt-5 mt-lg-0">
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/privacypolicy"}>
							{t("Privacy policy")}
						</Link>
					</Col>
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/faq"}>
							{t("FAQ")}
						</Link>
					</Col>
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/contact"}>
							{t("Contact us")}
						</Link>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default Footer;
