import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./TextFieldInputValidtion.settingsForm";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import { useTranslation } from "react-i18next";
import { isArray } from "lodash";
import { ToastContainer, toast } from "react-toastify";


/*
This component behaves as normal html select where user provides the endpoint with additional feature where its value can be filled in another 
component
*/

const TextFieldInputValidationCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });

	const [t, i18n] = useTranslation();

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			props.onChange(e, null);
		}
	};
	useEffect(() => {
		let state = { ...myState }
		let initialValue =  props.component.defaultValue || props.form._data[props.component.key] ;

		if (initialValue) {
			state.value = initialValue.value;
			state.tempValue = initialValue.value;
			if (props.component.disabled === true) {
				setMyState(state);
				props.setComponentState(state);

			} else {
				updateStateValue({
					target: { value: initialValue.value },
				});
			}
		}
		if ((props.form._form.settings.data[props.component.notifications[0].fieldKey]!=undefined ||props.form._form.settings.data[props.component.notifications[0].fieldKey]!=null) && !state.notificationsAppended && props.form._form.settings && props.component.notifications) {
			props.form._form.settings["notificationsOnSubmit"] = props.component.notifications;
			state.notificationsAppended = true;
			setMyState(state);
			props.setComponentState(state);


			//refresh the form to apply changes
			// props.form.triggerRedraw();
		}
	}, []);
	const updateStateValue = async (e) => {
		let newValue = e.target.value + "";
		let state = { ...myState }
		state.tempValue = newValue;
		state.value = newValue;
		// state.isValid = true;
		if (props.component.componentType === "number") {
			state.tempValue = newValue.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
		} else if (props.component.componentType === "letters") {
			state.tempValue = newValue.replace(/[^A-Za-z]/g, "");
		}
		setMyState({ ...state });
		props.setComponentState({ ...state });

		if (props.component.maxLength && props.component.disabled === false) {
			if (newValue.toString().length === parseInt(props.component.maxLength)) {
				state.isValid = true;
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
				var headers = { "Content-Type": "application/json" };

				if (accessToken) {
					var headerToken = { Authorization: "Bearer " + accessToken };
					headers = { ...headers, ...headerToken };
				} else {
					headers["SuperAdminToken"] = Constants.SuperAdminToken;
				}
				let valid = false;
				let val = {};
				if (props.component.fieldData && props.component.fieldData.length > 0) {
					for (let i = 0; i < props.component.fieldData.length; i++) {
						if (state.isValid === true) {
							let res = await fetch(Constants.base_url+props.component.fieldData[i].requestUrl, {
								method: "GET",
								headers: { ...headers },
							}).then((response) => response.json());
							if (res) {
								let validIndex = -1;

								let path = props.component.fieldData[i].dataPath;
								let result = path ? _.get(res, path, "") : res;

								if (isArray(result)) {
									for (let j = 0; j < result.length; j++) {
										if (result[j][props.component.key] + "" === newValue) {
											validIndex = j;
											val = { ...result[validIndex], value: newValue };
											break;
										}
									}

									if (props.component.fieldData[i].validIfFound) {
										if (validIndex !== -1) {
											valid = true;
										}
									} else {
										if (validIndex === -1) {
											valid = true;
										} else {
											valid = false;
											if (props.component.fieldData[i].notificationIfFound) {
												toast.error(t(props.component.fieldData[i].notificationIfFound), {
													position: toast.POSITION.TOP_RIGHT,
													autoClose: 3000,
												});
											}
										}
									}
								} else {
									if (result[props.component.key] + "" === state.value + "") {
										validIndex = 1;
										val = { value: newValue };
									}
									if (props.component.fieldData[i].validIfFound) {
										if (validIndex !== -1) {
											valid = true;
										}
									} else {
										if (validIndex === -1) {
											valid = true;
										} else {
											valid = false;
											if (props.component.fieldData[i].notificationIfFound) {
												toast.error(t(props.component.fieldData[i].notificationIfFound), {
													position: toast.POSITION.TOP_RIGHT,
													autoClose: 3000,
												});
											}
										}
									}
								}
								if (valid) {
									state.isValid = true;
									setMyState(state);
									props.setComponentState(state);

									updateValue(val);
								} else {
									state.isValid = false;
									state.value = "";
									setMyState(state);
									props.setComponentState(state);

									updateValue("");
								}
							}
						} else {
							state.isValid = false;
							state.value = "";
							setMyState(state);
							props.setComponentState(state);

							updateValue("");
						}
					}
				} else {
					setMyState(state);
					props.setComponentState(state);
					val = { value: newValue };
					updateValue(val);
				}
			} else {
				state.isValid = false;
				state.value = "";
				setMyState(state);
				props.setComponentState(state);

				updateValue("");
			}
		}

	};
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div>
			<input
				pattern={props.component.componentType === "letters" ? "[A-Za-z]" : props.component.componentType === "number" ? "[0-9]+" : ""}
				className="form-control"
				maxLength={parseInt(props.component.maxLength)}
				style={{
					border: myState.isValid === false && myState.tempValue !== "" ? "1px solid red" : "",
				}}
				disabled={props.component.disabled === true}
				value={myState["tempValue"]}
				onChange={(e) => updateStateValue(e)}
			/>
		
			<br />
			<em style={{ color: "red" }}>{(myState.isValid === false && myState.tempValue !== "") ? t("Invalid Input") : ""}</em>
		</div>
	);
};

export default class TextFieldInputValidation extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			value: "",
			requestUrl: "",
			tempValue: "",
			isValid: true,
			message: "",
			notificationsAppended: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "textFieldInputValidationCustomComp",
			label: "Text Field Input Validation",
		});
	}
	static get builderInfo() {
		return {
			title: "Text Field Input Validation",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: TextFieldInputValidation.schema(),
		};
	}
	static editForm = settingsForm;
	
	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;
		let key = this.component.key;
		const rootForm = this.getRoot(); // Get the root form object
		const root = createRoot(element);
		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);
			root.render(
				<TextFieldInputValidationCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					form={rootForm}
					element={this}
					data={this.data}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		}

		root.render(
			<TextFieldInputValidationCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				form={rootForm}
				element={this}
				data={this.data}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}
	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	validate(data, dirty, rowData) {
		if (!this.component.validate.required) {
			if (this.options.oldState.tempValue.trim() !== "") {
				if (this.options.oldState.isValid) {
					return true;
				}
			} else {
				return true;
			}
		} else {
			if (this.options.oldState.isValid) {
				return true;
			}
		}
		return false;
	}
}
