import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./Dropdown.settingsFrom";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import db from "indexedDB";
/*
This component behaves as normal html select where user provides the endpoint with additional feature where its value can be filled in another 
component
*/

const DropdownCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });
	const [t, i18n] = useTranslation();
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e, comState) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			let state = comState || { ...myState };

			const newValue = e.target.value; // Get the selected value from the event
			let selectedValues = state["options"].filter((v) => v.value === newValue)[0];
			if (props.component.relatedGrid) {
				props.form._data[props.component.relatedGrid] = [];
				props.form._submission.data[props.component.relatedGrid] = [];
				Utils.eachComponent(
					props.form.components,
					function (component) {
						if (component.component.key === props.component.relatedGrid) {
							if (component.editRows.filter((s) => s.state === "saved").length > 0) {
								for (let i = component.editRows.length - 1; i >= 0; i--) {
									component.removeRow(i);
								}
							}
						}
					},
					true
				);
			}
			if (selectedValues) {
				state["value"] = newValue;
				/*	if (props.component.componentsToFill) {
						for (let i = 0; i < props.component.componentsToFill.length; i++) {
							state["value"][props.component.componentsToFill[i]["fieldApi"]] = selectedValues.label;
						}
					}*/
				setMyState(state);
				props.setComponentState(state);

				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
					}
				}
				if (props.component.keysToAdd) {
					let newJson = {};
					for (let i = 0; i < props.component.keysToAdd.length; i++) {
						newJson[props.component.keysToAdd[i].fieldApi] = selectedValues[props.component.keysToAdd[i].fieldApi];
					}
					props.onChange({ label: selectedValues.label, value: newValue, ...newJson }, null);
				} else {
					props.onChange({ label: selectedValues.label, value: newValue }, null);
				}
			} else {
				state["value"] = "";
				setMyState(state);
				props.setComponentState(state);
				props.onChange("", null);
				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = "";
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = "";
					}
				}
			}
		}
	};
	useEffect(() => {
		let state = { ...myState };
		let initialValue = props.component.defaultValue || props.form._data[props.component.key];
		if (initialValue) {
			let label = initialValue?.label;
			let val = initialValue?.value;
			i18next.language = i18next.language.includes("-") ? i18next.language.split("-")[0] : i18next.language;
			//label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
			label = props.form.i18next.store.data[i18next.language][label] || [label];
			//	Object.keys(props.form.i18next.store.data[i18next.language].translation).map((key) => {
			let allLabels = props.form.i18next.store.data[i18next.language].translation;
			Object.keys(allLabels).map((key2) => {
				if (allLabels[key2] === label) {
					label = props.form.i18next.store.data[i18next.language].translation[key2];
				}
			});

			//	});

			if (props.component.disabled !== true) {
				state["value"] = val;
				state["label"] = label;
			} else {
				state["value"] = label;
				state["options"] = [{ label: label, value: label }];
				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = label;
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = label;
					}
				}
			}
		}
		if (props.component.disabled !== true) {
			if (props.component.disabled !== state.wasDisabled) {
				loadData(state);
			} else {
				if (props.component.disabled !== state.wasDisabled) {
					loadData(state);
				}
			}
		} else {
			setMyState(state);
			props.setComponentState(state);
		}
	}, []);

	//ON LOAD
	const loadData = async (state) => {
		if (props.component.requestUrl !== "" && props.component.requestUrl) {
			try {
				const cachedData = await db.table("dropdown_data").where("url").equals(props.component.requestUrl).first();

				if (cachedData) {
					const data = JSON.parse(cachedData.data);
					const path = props.component.dataPath;
					let processedData = path ? _.get(data, path, "") : data;

					if (typeof processedData === "string") {
						processedData = JSON.parse(processedData);
					}

					setOptions(processedData, state);
					return;
				}
			} catch (dbError) {
				console.error("Error accessing IndexedDB:", dbError);
			}

			// Fetch data from the server if indexedDB is not available
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			} else {
				headers["SuperAdminToken"] = Constants.SuperAdminToken;
			}

			fetch(Constants.base_url + props.component.requestUrl, {
				method: "GET",
				headers: { ...headers },
			})
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						let path = props.component.dataPath;
						let data = path ? _.get(res, path, "") : res;
						if (typeof data === "string") {
							data = JSON.parse(data);
						}
						setOptions(data, state);
					}
				})
				.then(() => {})
				.catch((error) => {
					setMyState(state);
					props.setComponentState(state);
				});
		}
	};

	const setOptions = (data, state) => {
		let allData = [];

		i18next.language = i18next.language.includes("-") ? i18next.language.split("-")[0] : i18next.language;

		for (let i = 0; i < data.length; i++) {
			let label = _.get(data[i], props.component.labelPath, "") || data[i].label;
			let value = _.get(data[i], props.component.valuePath, "") || data[i].value;

			allData.push({
				label: props.component.translateData ? props.form.i18next.store.data[i18next.language].translation[label] || label : label,
				value: value,
			});
			if (props.component.keysToAdd) {
				for (let j = 0; j < props.component.keysToAdd.length; j++) {
					allData[i][props.component.keysToAdd[j].fieldApi] = data[i][props.component.keysToAdd[j].fieldApi];
				}
			}
		}

		if (props.component.isOthers !== "" && props.component.isOthers !== undefined) {
			const othersData = JSON.parse(props.component.isOthers);

			// Add each key-value pair as an option
			for (const [label, value] of Object.entries(othersData)) {
				allData.push({
					label: t(label), // Key from the JSON
					value: value, // Value from the JSON
				});
			}
		}

		state["loading"] = false;
		state.wasDisabled = props.component.disabled === true;
		if (allData.length > 0) {
			state["options"] = allData;
			if (props.component.isDefaultValue) {
				state["value"] = allData[props.component.fieldDefaultValue].value; // Set the first option's value
				//state["label"] = allData[props.component.fieldDefaultValue].label; // Store the label

				props.form._data[props.component.key] = allData[props.component.fieldDefaultValue].value; // Update form data
				props.form._submission.data[props.component.key] = allData[props.component.fieldDefaultValue].value; // Update submission data
				//	console.log("defff",allData[props.component.fieldDefaultValue])
				props.onChange(allData[props.component.fieldDefaultValue], null);
			}

			setMyState(state);
			props.setComponentState(state);
		} else {
			setMyState(state);
			props.setComponentState(state);
		}

		if (props.component.initialValue) {
			updateValue({ target: { value: props.component.initialValue + "" } }, state);
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<>
			<CustomSelect component={props.component} disabled={props.component.disabled === true} value={myState["value"]} options={myState["options"]} updateValue={updateValue} />
		</>
	);
};

export default class Dropdown extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: {},
			wasDisabled: null,
			requestUrl: "",
			mainData: [],
			girdRefreshed: false,
			loading: false,
			initialValueSet: false,
			initializedInsideGrid: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "dropdownCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Dropdown",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Dropdown.schema(),
		};
	}
	static editForm = settingsForm;
	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		// let randomId = getRandomId();
		// // The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// // It can load a single element or multiple elements with the same ref.
		// this.loadRefs(element, {
		// 	[`react-${this.id}`]: "single",
		// });

		// if (this.refs[`react-${this.id}`]) {
		// 	this.attachReact(this.refs[`react-${this.id}`], this.setReactInstance.bind(this));
		// 	if (this.shouldSetValue) {
		// 		this.setValue(this.dataForSetting);
		// 		this.updateValue(this.dataForSetting);
		// 	}
		// }
		return Promise.resolve();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;
		let key = this.component.key;
		const rootForm = this.getRoot(); // Get the root form object
		const root = createRoot(element);
		let grid = "";

		if (rootForm) {
			Utils.eachComponent(
				rootForm.components,
				function (component) {
					if (component.component.type === "editgrid" && !insideGrid) {
						grid = component;

						Utils.eachComponent(
							component.component.components,
							function (component2) {
								if (!insideGrid) {
									insideGrid = component2.key === key;
								} else {
								}
							},
							true
						);
					}
				},
				true
			);
		}
		if (insideGrid) {
			if (grid.editRows[this.rowIndex]?.state === "new" && !this.options["oldState"].initializedInsideGrid) {
				this.component.defaultValue = null;
				grid.editRows[this.rowIndex].data[key] = null;
				this.options["oldState"].initializedInsideGrid = true;
				this.options["oldState"].rowIndex = this.rowIndex;
			} else {
				this.component.defaultValue = grid.editRows[this.rowIndex]?.data[key] || rootForm._data[this.rowIndex]?.data[key];
			}
		}
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);

			root.render(
				<DropdownCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					form={rootForm}
					element={this}
					data={this.data}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
					updateOnChange={this.updateOnChange}
				/>
			);
		};

		root.render(
			<DropdownCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				form={rootForm}
				element={this}
				data={this.data}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
				updateOnChange={this.updateOnChange}
			/>
		);
	}
}
