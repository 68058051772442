import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext from "context/Context";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Carousel, Container } from "react-bootstrap";
import styles from "./landing.module.css";
import { useTranslation } from "react-i18next";
import audit from "assets/img/audit.png";
import crisis from "assets/img/crisis.png";
import time from "assets/img/time.png";
import reports from "assets/img/reports.png";
import { getAllDatabaseRecords } from "components/workflow/WorkflowHelper";

const LandingButtons = ({ img, text, rowSize, textColor }) => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const [features, setFeatures] = useState([]);
	const getTableData = async (tableName, fieldValue) => {
		try {
			const result = await getAllDatabaseRecords(tableName, fieldValue);
			return result; // return the result if the function succeeds
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // rethrow the error to be handled elsewhere if necessary
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTableData("features", "show=eq.true");
				if (response?.data?.dataBaseContent) {
					const mappedData = response.data.dataBaseContent.map((item) => ({
						title: t(item.title),
						icon: item.image,
						description: t(item.description),
						id: item.id,
						order: item.order,
					}));
					const sortedData = mappedData.sort((a, b) => a.order - b.order);
					setFeatures((prev) => [...prev, ...sortedData]);
				} else {
					console.error("Invalid response structure:", response);
				}
			} catch (error) {
				console.error("Error fetching features data:", error);
			}
		};
		fetchData();
	}, []);

	return (
		<Container className={`platform-info ${styles.padding35em}`}>
			<Row>
				{features.map((feature, index) => (
					<Col key={index} md={3} className="text-center">
						<div className={`icon ${styles.iconImages}`}>
							<img src={feature.icon} className={`icon ${styles.iconImagesMax}`} />
						</div>
						<h4 className={`icon ${styles.buttonsTitle} mb-3`} style={textColor ? { color: textColor } : undefined}>
							{feature.title}
						</h4>
						{feature.description && <p className={`icon ${styles.buttonsSubTitle}`}>{feature.description}</p>}
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default LandingButtons;
